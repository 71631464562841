import AsyncSelect from "react-select/async";

export function DropdownAsync({label, fetchOptions, defaultValue, onChange, disabled, isError, ...props}) {
	const filterDatas = (data, inputValue) => {
		return data.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));
	};

	const loadOptions = (inputValue, callback) => {
		if (inputValue.length === 0) {
			callback([]);

			return;
		}

		if (typeof fetchOptions === "function")
			fetchOptions(inputValue, data => callback(filterDatas(data ?? [], inputValue)));
	};

	return (
		<AsyncSelect
			{...props}
			cacheOptions
			components={{
				IndicatorSeparator: () => null,
			}}
			defaultValue={defaultValue}
			isDisabled={disabled}
			loadOptions={loadOptions}
			placeholder={label}
			styles={{
				control: baseStyles => ({
					...baseStyles,
					borderColor: isError ? "#f21a1a" : "#e8e8e8",
					minHeight: 50,
					paddingTop: 6,
					paddingBottom: 6,
					boxShadow: "none",
					"&:hover": {
						borderColor: isError ? "#f21a1a" : "#e8e8e8",
					},
					"&:focus-within": {
						borderColor: "#45969b",
					},
				}),
				multiValue: base => ({
					...base,
					backgroundColor: "#FFF7E1",
					color: "#F36E21",
				}),
				multiValueLabel: base => ({
					...base,
					color: "#F36E21",
				}),
				multiValueRemove: base => ({
					...base,
					"&:hover": {
						backgroundColor: "rgba(243, 110, 33, 0.05)",
					},
				}),
				dropdownIndicator: baseStyles => ({
					...baseStyles,
					color: "#25282b",
				}),
			}}
			onChange={onChange}
		/>
	);
}
