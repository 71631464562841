import {useMemo, useState} from "react";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import _ from "lodash";
import {isMobile} from "react-device-detect";
import {useHistory} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {usePost} from "hooks/Doctor/Post";
import {loadLocalAssets} from "utils/loader";

function getLastUpdateTime(dateStr) {
	const diffrenceDate = dayjs().diff(dayjs(dateStr), "days");

	if (diffrenceDate === 0) {
		const diffrenceMinutes = dayjs().diff(dayjs(dateStr), "minutes");

		return `${diffrenceMinutes} minutes ago`;
	}

	return `${diffrenceDate} days ago`;
}

export function PostList() {
	const history = useHistory();

	const userProfile = useSelector(state => state.AuthReducer.profile);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, filterPosts} = usePost(userProfile.id);

	const columns = useMemo(
		() => [
			{
				Header: "Nama Pasien",
				accessor: "thumbnail",
				Cell: ({row, value}) => {
					const {title, authors, updatedAt} = row?.original ?? {};
					const authorName = `${authors[0]?.firstName ?? ""} ${authors[0]?.lastName ?? ""}`;

					return (
						<div className="post-items">
							<img
								alt="Avatar"
								class="img-fluid"
								src={!_.isEmpty(value) ? value : loadLocalAssets("img/no-image-post.png")}
							/>

							<div>
								<div className="post-item-title">{!_.isEmpty(title) ? title : "Untitled"}</div>
								<div className="post-item-subtitle">
									By {authorName} in Umum - {getLastUpdateTime(updatedAt)}
								</div>
							</div>
						</div>
					);
				},
			},
			{
				Header: "Status",
				accessor: "status",
				width: 150,
				Cell: ({value}) => {
					if (value === "posted") {
						return <div className="box-status onproses">Published</div>;
					}

					if (value === "draft") {
						return <div className="box-status cancel">Draft</div>;
					}

					if (value === "scheduled") {
						return <div className="box-status selesai">Scheduled</div>;
					}
				},
			},
			{
				Header: "Aksi",
				accessor: "id",
				width: 50,
				Cell: ({row}) => (
					<>
						<a class="post-edit-btn" onClick={() => history.push(`/doctor/post/editor/${row?.values?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Pasien",
				accessor: "thumbnail",
				Cell: ({row, value}) => {
					const {title, authors, updatedAt} = row?.original ?? {};
					const authorName = `${authors[0]?.firstName ?? ""} ${authors[0]?.lastName ?? ""}`;

					return (
						<div className="post-items">
							<img
								alt="Avatar"
								class="img-fluid"
								src={!_.isEmpty(value) ? value : loadLocalAssets("img/no-image-post.png")}
							/>

							<div>
								<div className="post-item-title">{!_.isEmpty(title) ? title : "Untitled"}</div>
								<div className="post-item-subtitle">
									By {authorName} in Umum - {getLastUpdateTime(updatedAt)}
								</div>
							</div>
						</div>
					);
				},
			},
			{
				Header: "Status",
				accessor: "status",
				width: 150,
				Cell: ({value, row}) => {
					let statusElement = null;

					if (value === "posted") {
						statusElement = <div className="box-status onproses">Published</div>;
					}

					if (value === "draft") {
						statusElement = <div className="box-status cancel">Draft</div>;
					}

					if (value === "scheduled") {
						statusElement = <div className="box-status selesai">Scheduled</div>;
					}

					return (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-end",
								alignSelf: "flex-start",
							}}>
							{statusElement}{" "}
							<a
								class="post-edit-btn"
								onClick={() => history.push(`/doctor/post/editor/${row?.original?.id}`)}>
								<span class="icon-ico-pencil ft-18" />
							</a>
						</div>
					);
				},
			},
		],
		[],
	);

	return (
		<DashboardLayout>
			<div className="mb-3" style={{display: "flex", alignItems: "center"}}>
				<div style={{flexGrow: 1}}>
					<div className="content-title">Posts</div>
				</div>
				<button
					className="btn btn-orange content-btn r8"
					text="Save"
					onClick={() => history.push("/doctor/post/editor")}>
					New Post
				</button>
			</div>

			<div className="mb-3" style={{display: "flex", alignItems: "center"}}>
				<div style={{flexGrow: 1}}>
					<div className="content-title" style={{fontSize: 14, fontWeight: "600"}}>
						Total Posts <span className="post-counter">{data?.data?.totalItems}</span>
					</div>
				</div>

				<SearchBox
					className="post-search-box"
					searchPlaceholder="Search post"
					onSearch={keyword => {
						setSearchKeyword(keyword);
						filterPosts({page: 1, limit: dataLimit, search: keyword});
					}}
				/>
			</div>

			<div className={`post-table${(data?.data?.totalItems ?? 0) === 0 ? " no-data" : ""}`}>
				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={data?.data?.rows ?? []}
					limit={dataLimit}
					loading={isLoading}
					title="Data Appointment"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setDataLimit(limitValue);
						filterPosts({page: 1, limit: limitValue, search: searchKeyword});
					}}
					onPageChange={selectedPage =>
						filterPosts({page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</div>
		</DashboardLayout>
	);
}
