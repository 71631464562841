import {createContext, useContext, useState} from "react";

const layoutContext = createContext({
	selectedShowSubmenu: "",
	onSetSelectedSubmenu: () => {},
});

export default function LayoutContextProvider({children}) {
	const [selectedShowSubmenu, setSelectedShowSubmenu] = useState(null);

	const onSetSelectedSubmenu = value => setSelectedShowSubmenu(value !== selectedShowSubmenu ? value : null);

	return (
		<layoutContext.Provider value={{selectedShowSubmenu, onSetSelectedSubmenu}}>{children}</layoutContext.Provider>
	);
}

export const useLayoutContext = () => useContext(layoutContext);
