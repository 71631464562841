import {useEffect} from "react";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {$createHeadingNode} from "@lexical/rich-text";
import {$getRoot} from "lexical";

import {ADD_HEADER} from "../constant";

export function HeaderPlugin() {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		const unregisterCommand = editor.registerCommand(
			ADD_HEADER,
			payload => {
				editor.update(() => {
					const root = $getRoot();
					const headerNode = $createHeadingNode(`h${payload}`);

					root.append(headerNode);
					headerNode.select();
				});
			},
			1,
		);

		return () => unregisterCommand();
	}, [editor]);

	return null;
}
