import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {useEffect} from "react";

import {ADD_FILE} from "../constant";

export function FilePlugin() {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		const unregisterCommand = editor.registerCommand(
			ADD_FILE,
			() => {
				editor.update(() => {});
			},
			1,
		);

		return () => unregisterCommand();
	}, [editor]);

	return null;
}
