export const ADD_PARAGRAPH = "ADD_PARAGRAPH";
export const ADD_QUOTE = "ADD_QUOTE";
export const ADD_CALLOUT = "ADD_CALLOUT";
export const ADD_FILE = "ADD_FILE";
export const ADD_HEADER = "ADD_HEADER";
export const ADD_HTML = "ADD_HTML";
export const ADD_IMAGE = "ADD_IMAGE";
export const ADD_INSTAGRAM = "ADD_INSTAGRAM";
export const ADD_YOUTUBE = "ADD_YOUTUBE";
export const ADD_TOGGLE = "ADD_TOGGLE";
export const ADD_TWITTER = "ADD_TWITTER";
export const ADD_VIDEO = "ADD_VIDEO";

export const INITIAL_VALUES = {
	userId: null,
	title: "",
	slug: "",
	content: "",
	publishDate: "",
	tagIds: [],
	authorIds: [],
	postAccess: "public",
	excerpt: "",
	thumbnail: "",
	status: "", //draf,posted,scheduled,
	time: "", //HH:mm
	meta_title: "",
	meta_description: "",
};

export const EDITOR_CONFIG = {
	namespace: "Promedik Editor",
	theme: {
		code: "pe-code",
		heading: {
			h1: "pe-heading-h1",
			h2: "pe-heading-h2",
			h3: "pe-heading-h3",
			h4: "pe-heading-h4",
			h5: "pe-heading-h5",
		},
		image: "pe-image",
		link: "pe-link",
		list: {
			listitem: "pe-listitem",
			nested: {
				listitem: "pe-nested-listitem",
			},
			ol: "pe-list-ol",
			ul: "pe-list-ul",
		},
		ltr: "ltr",
		paragraph: "pe-paragraph",
		placeholder: "pe-placeholder",
		quote: "pe-quote",
		rtl: "rtl",
		text: {
			bold: "pe-text-bold",
			code: "pe-text-code",
			hashtag: "pe-text-hashtag",
			italic: "pe-text-italic",
			overflowed: "pe-text-overflowed",
			strikethrough: "pe-text-strikethrough",
			underline: "pe-text-underline",
			underlineStrikethrough: "pe-text-underline-strikethrough",
		},
	},
};

export const POST_ACCESS_OPTS = [
	{label: "Public", value: "public"},
	{label: "Only Me", value: "only-me"},
];

export const COMMON_TOOLBAR_ITEMS = [
	{
		title: "Paragraph",
		description: "Insert new paragraph",
		image: "img/paragraph.svg",
		key: ADD_PARAGRAPH,
	},
	{
		title: "Image",
		description: "Upload, or embed with /image [url]",
		image: "img/image.svg",
		// key: ADD_IMAGE,
	},
	{
		title: "Video",
		description: "Upload and play a video",
		image: "img/video.svg",
		// key: ADD_VIDEO,
	},
	{
		title: "File",
		description: "Upload a downloadable file",
		image: "img/file.svg",
		// key: ADD_FILE,
	},
	{
		title: "Quote",
		description: "Insert a new quote",
		image: "img/quote.svg",
		key: ADD_QUOTE,
	},
	{
		title: "Header",
		description: "Insert a new header",
		image: "img/header.svg",
		key: ADD_HEADER,
		payload: 2,
	},
];

export const FORMATTING_TOOLBAR_ITEMS = [
	{
		title: "Callout",
		description: "Info boxes that stand out",
		image: "img/callout.svg",
		// key: ADD_CALLOUT,
	},
	{
		title: "Toggle",
		description: "Add a collapsible content",
		image: "img/toggle.svg",
		// key: ADD_TOGGLE,
	},
	{
		title: "HTML",
		description: "Insert a raw HTML card",
		image: "img/html.svg",
		// key: ADD_HTML,
	},
];

export const EMBEDS_TOOLBAR_ITEMS = [
	{
		title: "Twitter",
		description: "/twitter [tweet url]",
		image: "img/twitter.svg",
		// key: ADD_TWITTER,
	},
	{
		title: "Youtube",
		description: "/youtube [youtube url]",
		image: "img/youtube.svg",
		// key: ADD_YOUTUBE,
	},
	{
		title: "Instagram",
		description: "/instagram [instagram url]",
		image: "img/instagram.svg",
		// key: ADD_INSTAGRAM,
	},
];
