import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./useQueryPost";

export function useAddPost(options = {}) {
	return useMutation(values => axios.post("/article/create", values).then(res => res.data));
}

export function useEditPost(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(values => axios.post("/article/update", values).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}

export function useDeletePost(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(values => axios.post("/article/delete", values).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}
