/* eslint-disable eqeqeq */
export function BasicDropdown({
	name,
	data,
	value,
	onSelect,
	onBlur,
	onChange,
	disabled,
	className = "form-select",
	emptyText = "Data tidak ditemukan ",
	placeholder,
	returnObject,
	...props
}) {
	return (
		// <select class="form-select" disabled={disabled} name={name} value={value} onChange={onChange}>
		<select
			{...props}
			class={className}
			disabled={disabled}
			value={value?.value}
			onBlur={onBlur}
			onChange={e => {
				if (returnObject) {
					onSelect(e.target.value, data?.find(item => item.value == e.target.value)?.objectData);
				} else {
					onSelect(e.target.value);
				}
			}}>
			{placeholder && (
				<option disabled={true} selected={true} value="">
					{placeholder}
				</option>
			)}
			{data.length &&
				data?.map((item, key) => (
					<option
						{...(props?.defaultValue ? {} : {selected: item.value == value})}
						key={key}
						value={item.value}>
						{item.label}
					</option>
				))}
			{!data.length && (
				<option className="text-center" disabled={true}>
					{emptyText}
				</option>
			)}
		</select>
	);
}
