import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {loadLocalAssets} from "utils/loader";

import {COMMON_TOOLBAR_ITEMS, FORMATTING_TOOLBAR_ITEMS, EMBEDS_TOOLBAR_ITEMS} from "./constant";

export function PostAddEditorToolbar({isOpen, onClose}) {
	const [editor] = useLexicalComposerContext();

	const handleOnToolbarPress = (key, payload) => () => {
		if (!key) return;

		editor.dispatchCommand(key, payload);
	};

	const renderToolbarItems = key => (item, index) => {
		return (
			<button
				key={`${key}-${index}`}
				className="btn w-100 d-flex align-items-center text-start p-0"
				style={{marginBottom: 12}}
				type="button"
				onClick={handleOnToolbarPress(item?.key, item?.payload)}>
				<div className="toolbar-item-icon">
					<img alt="toolbar-item-icon" src={loadLocalAssets(item.image)} />
				</div>
				<div className="ms-4">
					<div className="toolbar-item-title">{item.title}</div>
					<div className="toolbar-item-description">{item.description}</div>
				</div>
			</button>
		);
	};

	return (
		<div className="sidebar toolbar-sidebar">
			<div className="nav-box">
				<div className="logo">
					<div className="logo-title">Blocks</div>

					<button
						className="btn btn-editor btn-editor-toolbar-trigger p-0"
						type="button"
						onClick={onClose(!isOpen)}>
						<span className="icon-ico-close" />
					</button>
				</div>

				<div className="form d-flex flex-column">
					<div className="toolbar-category-title">Common</div>
					<div className="mb-4">{COMMON_TOOLBAR_ITEMS.map(renderToolbarItems("common"))}</div>

					<div className="toolbar-category-title">Formatting</div>
					<div className="mb-4">{FORMATTING_TOOLBAR_ITEMS.map(renderToolbarItems("formatting"))}</div>

					<div className="toolbar-category-title">Embeds</div>
					<div className="mb-4">{EMBEDS_TOOLBAR_ITEMS.map(renderToolbarItems("embeds"))}</div>
				</div>
			</div>
		</div>
	);
}
