import axios from "services/axios";
import {useState} from "react";
import {useQuery} from "react-query";

export async function getTagPost(id, filter) {
	if (!id) {
		const {data} = await axios.get("/master-tag-post", {params: {...filter, sortBy: "id.desc"}});

		return data;
	}

	const {data} = await axios.get(`/master-tag-post/${id}`);

	return data;
}

export const QUERY_TAG_POST = "master-tag-post";

export const useTagPost = () => {
	const [filter, setFilter] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [tagPostId, setTagPostId] = useState(null);
	const {
		data = [],
		isLoading,
		isError,
		error,
	} = useQuery([QUERY_TAG_POST, filter, tagPostId], async () => getTagPost(tagPostId, filter));

	return {data, isLoading, isError, error, filter, setFilter, setTagPostId};
};
