import {createPortal} from "react-dom";
import {computePosition} from "@floating-ui/dom";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {$getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND} from "lexical";
import {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {usePointerInteractions} from "hooks/usePointerInteractions";
import {loadLocalAssets} from "utils/loader";

const DOM_ELEMENT = document.body;

const FloatingMenu = forwardRef(function FloatingMenu(props, ref) {
	const {editor, coords} = props;

	const shouldShow = coords !== undefined;

	const [state, setState] = useState({
		isBold: false,
		isCode: false,
		isItalic: false,
		isStrikethrough: false,
		isUnderline: false,
	});

	useEffect(() => {
		const unregisterListener = editor.registerUpdateListener(({editorState}) => {
			editorState.read(() => {
				const selection = $getSelection();

				if (!$isRangeSelection(selection)) return;

				setState({
					isBold: selection.hasFormat("bold"),
					isCode: selection.hasFormat("code"),
					isItalic: selection.hasFormat("italic"),
					isStrikethrough: selection.hasFormat("strikethrough"),
					isUnderline: selection.hasFormat("underline"),
				});
			});
		});

		return unregisterListener;
	}, [editor]);

	return (
		<div
			ref={ref}
			aria-hidden={!shouldShow}
			className="d-flex items-center gap-1 justify-between p-1"
			style={{
				position: "absolute",
				top: coords?.y ?? 0,
				left: coords?.x ?? 0,
				visibility: shouldShow ? "visible" : "hidden",
				opacity: shouldShow ? 1 : 0,
				borderWidth: 1,
				borderStyle: "solid",
				borderColor: "#e8e8e8",
				backgroundColor: "#fff",
				borderRadius: 8,
			}}>
			<button
				active={state.isBold.toString()}
				aria-label="Format text as bold"
				className="btn btn-floating-format"
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
				}}>
				<img alt="toolbar-item-icon" src={loadLocalAssets("img/bold.svg")} />
			</button>
			<button
				active={state.isItalic.toString()}
				aria-label="Format text as italics"
				className="btn btn-floating-format"
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
				}}>
				<img alt="toolbar-item-icon" src={loadLocalAssets("img/italic.svg")} />
			</button>
			<button
				active={state.isUnderline.toString()}
				aria-label="Format text to underlined"
				className="btn btn-floating-format"
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
				}}>
				<img alt="toolbar-item-icon" src={loadLocalAssets("img/underline.svg")} />
			</button>
			<button
				active={state.isStrikethrough.toString()}
				aria-label="Format text with a strikethrough"
				className="btn btn-floating-format"
				onClick={() => {
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
				}}>
				<img alt="toolbar-item-icon" src={loadLocalAssets("img/strikethrough.svg")} />
			</button>
		</div>
	);
});

export function FloatingMenuPlugin() {
	const ref = useRef(null);
	const [coords, setCoords] = useState(undefined);
	const [editor] = useLexicalComposerContext();

	const {isPointerDown, isPointerReleased} = usePointerInteractions();

	const calculatePosition = useCallback(() => {
		const domSelection = getSelection();
		const domRange = domSelection?.rangeCount !== 0 && domSelection?.getRangeAt(0);

		if (!domRange || !ref.current || isPointerDown) return setCoords(undefined);

		computePosition(domRange, ref.current, {placement: "top"})
			.then(pos => {
				setCoords({x: pos.x, y: pos.y - 10});
			})
			.catch(() => {
				setCoords(undefined);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPointerDown]);

	const $handleSelectionChange = useCallback(() => {
		if (editor.isComposing() || editor.getRootElement() !== document.activeElement) {
			setCoords(undefined);

			return;
		}

		const selection = $getSelection();

		if ($isRangeSelection(selection) && !selection.anchor.is(selection.focus)) {
			calculatePosition();
		} else {
			setCoords(undefined);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editor, calculatePosition]);

	useEffect(() => {
		const unregisterListener = editor.registerUpdateListener(({editorState}) => {
			editorState.read(() => $handleSelectionChange());
		});

		return unregisterListener;
	}, [editor, $handleSelectionChange]);

	const show = coords !== undefined;

	useEffect(() => {
		if (!show && isPointerReleased) {
			editor.getEditorState().read(() => $handleSelectionChange());
		}
		// Adding show to the dependency array causes an issue if
		// a range selection is dismissed by navigating via arrow keys.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPointerReleased, $handleSelectionChange, editor]);

	return createPortal(<FloatingMenu ref={ref} coords={coords} editor={editor} />, DOM_ELEMENT);
}
